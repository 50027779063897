html{
  min-height: 100%;
}
body {
  padding: 0px;
  max-width: 90%;
  margin: 0 auto;
  overflow: auto;
  font-family: Arial;
  color:black;
  font-size: 30px;
  width: 100wh;
  height: 90vh;
  background: linear-gradient(-45deg, #5e76df, #5698f0, #237fd5, #23D5AB);
  background-size: 400% 400%;
  -webkit-animation: Gradient 50s ease infinite;
  -moz-animation: Gradient 50s ease infinite ;
  animation: Gradient 50s ease infinite ;
  animation-duration : 25s;
}

.paused {
  -webkit-animation-play-state: paused; /* Safari 4.0 - 8.0 */
  animation-play-state: paused;
}

@-webkit-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

@-moz-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

@keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

h1,
h6 {
  font-family: 'Verdana';
  font-weight: 300;
  text-align: center;
  position: absolute;
  top: 45%;
  right: 0;
  left: 0;
  }

  p{
    font-size: 27px;
    font-family: 'Arial';
  }

button {
  font-family: 'Verdana';
  font-weight: 300;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 0;
  left: 48%;
}

ul.header li {
  display: inline;
  list-style-type: none;
}
ul.header {
  padding: 0;
  justify-content: center;  
}
ul.header li a {
  margin: 0 auto;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.active {
  background-color: rgba(0, 0, 0, 0.267);
  color: black;
  text-decoration-line: underline;
}

.pages {
  
  padding: 50px;
  background: linear-gradient(-45deg, #ee76527a, #e73c7e73, #23a5d55b, #23D5AB);
  border: solid;
  border-width: 2px;
  box-shadow: 5px 10px;
  font-size: 21px;
}

.pages h2 {
  padding: 0;
  margin: 0 auto;
  height: 150px ;
  text-align: center;
  font-size: 50px;
}
.pages h3{
  padding: 0;
  margin: 0 auto;
  height: 120px ;
  text-align: center;
  font-size: 50px;
}
.pages li {
  height: 0px;
  margin-bottom: 5px;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.copyright {
  text-align: right;
  font-size: 14px;
}

